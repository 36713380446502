import React from "react";
import "./mainblock.styles.css";



const MainBlock = () => {
  return (
    <div id="home" className="main">
    </div>
  );
};

export default MainBlock;